
.moocow-button {
	border: none;
	cursor: pointer;
	font-family: 'Open Sans', sans-serif;
	padding: 0.5em 1em;
	text-align: left;
	transition: background-color 0.25s, border-color 0.25s;
}

.moocow-button-standard {
	background-color: var(--background-colour);
	color: var(--font-colour);
}

.moocow-button-standard:hover,
.moocow-button-standard:active {
	background-color: var(--link-colour-hover);
}

.moocow-button-primary {
	background-color: var(--link-colour);
	color: var(--background-colour);
	text-align: center;
}

.moocow-button-primary:hover,
.moocow-button-primary:active {
	background-color: var(--link-colour-hover);
}

.moocow-button-transparent {
	background-color: transparent;
	border: none;
}
