
button {
	font: inherit;
	font-family: 'Open Sans', sans-serif;
}

form {
	font-family: 'Open Sans', sans-serif;
}

input {
	font: inherit;
}

textarea {
	line-height: inherit;
	max-height: 50em;
	min-height: 25em;
}
