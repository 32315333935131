/**
 * Styles for the main area of content.
 */

.moocow-content {
	background-color: var(--background-colour-transparent);
	display: grid;
	grid-template-columns: minmax(1rem, 5%) 1fr minmax(1rem, 5%);
	margin: 0 auto 1em;
	max-width: 50rem;
	position: relative;
}

@media (min-width: 50rem) {
	.moocow-content {
		margin-top: -10em;
	}
}

.moocow-content > * {
	grid-column: 2;
	grid-row: 2;
}

.moocow-content::before {
	content: '';
	grid-column: 1;
	grid-row: 1;
	padding-top: 100%;
}

.moocow-content::after {
	content: '';
	grid-column: 3;
	grid-row: 3;
	padding-top: 100%;
}

.moocow-heroimage-none .moocow-content {
	margin-top: -40vh;
}

@media (hover: hover) and (prefers-reduced-motion: no-preference) { /* stylelint-disable-line */

	.moocow-content {
		margin: var(--hero-image-height) auto 1em;
	}

	.moocow-heroimage-none .moocow-content {
		margin-top: 20vh;
	}

	@media (min-width: 34.375rem) {
		.moocow-heroimage-none .moocow-content {
			margin-top: 30vh;
		}
	}

	@media (min-width: 50rem) {
		.moocow-content {
			margin: calc(var(--hero-image-height) - 10em) auto 1em;
		}

		.moocow-heroimage-none .moocow-content {
			margin-top: 40vh;
		}
	}
}
