/**
 * Parallax effect classes.
 */

@media (hover: hover) and (prefers-reduced-motion: no-preference) { /* stylelint-disable-line */

	.moocow-parallax--base {
		height: 100vh;
		overflow-x: hidden;
		overflow-y: scroll;
		perspective: 1px;
		scroll-behavior: smooth;
		transform-style: preserve-3d;
	}

	.moocow-parallax--layer {
		bottom: 0;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		transform: translateZ(-1px) scale(2.05);
		z-index: -1;
	}
}
