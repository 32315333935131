/**
 * Hero image atop each page, and the variations therein.
 */

:root {
	--hero-image-height: 70vh;
}

@media (min-width: 50rem) {
	:root {
		--hero-image-height: 100vh;
	}
}

.moocow-heroimage {
	background: url('https://res.cloudinary.com/moocow/image/fetch/f_auto,q_auto/https://images.ultraq.net.nz/heroimage-default.jpg') center no-repeat;
	background-size: cover;
	height: var(--hero-image-height);
	width: 100%;
}

.moocow-heroimage img {
	height: 100%;
	object-fit: cover;
	width: 100%;
}
