/**
 * Styles applied at the root and body level.
 */

* {
	box-sizing: border-box;
}

*::before,
*::after {
	box-sizing: border-box;
}

body {
	background-color: var(--background-colour);
	color: var(--font-colour);
	font: 1em / 1.6 'Merriweather', serif;
	margin: 0;
}
