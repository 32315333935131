
.moocow-sociallinks {
	align-items: center;
	display: flex;
	height: 40px;
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.moocow-sociallink {
	height: 30px;
	width: 30px;
}

.moocow-sociallink + .moocow-sociallink {
	margin-left: 1em;
}

.moocow-sociallink a {
	background-position: center;
	background-size: 30px;
	border-bottom: none;
	display: block;
	height: 100%;
	opacity: 0.5;
	transition: opacity 0.25s;
	width: 100%;
}

.moocow-sociallink a:active,
.moocow-sociallink a:hover {
	opacity: 1;
}

#link-github {
	background-image: url('/images/Logo_GitHub_Dark.png');
}

#link-twitter {
	background-image: url('/images/Logo_Twitter_Blue.png');
	background-size: 50px;
}

@media (prefers-color-scheme: dark) {
	#link-github {
		background-image: url('/images/Logo_GitHub_Light.png');
	}

	#link-twitter {
		background-image: url('/images/Logo_Twitter_White.png');
	}
}
