
@keyframes content-fade {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

@keyframes content-appear {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
