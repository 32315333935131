/**
 * For display of inline images or embedded content.
 */

figure {
	/*display: flex; // Can't have this flex right now as it breaks the embedded video stuff 😢 */
	margin: 0;
	max-width: 100%;
	position: relative;
}

figure * {
	margin: 0 auto;
}

figure a {
	border-bottom: none;
}

figure iframe,
figure object {
	bottom: 0;
	height: 100%;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	width: 100%;
}

figcaption {
	font-size: 0.9em;
	font-style: italic;
	left: 0;
	margin: var(--gap-size-xsmall);
	text-align: center;
}

* + figure {
	margin-top: var(--gap-size);
}
