/**
 * TODO: This is an old class embedded in some blog posts.  I should find some
 *       time to update those posts so that this file isn't needed any more.
 */

.review .info {
	color: var(--font-colour-secondary);
	font: 0.85em 'Open Sans', sans-serif;
}

.review-image {
	float: right;
	margin-left: 1em;
}
