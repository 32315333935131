/**
 * Styles for the aspect ratio containers throughout the site.
 * 
 * TODO: This stuff isn't following the naming convention because it's baked
 *       into old blog posts!  I'll need to update those someday...
 */

.aspect-ratio-4-3,
.aspect-ratio-16-9 {
	position: relative;
}

.aspect-ratio-4-3 .aspect-ratio {
	padding-top: 75%;
}

.aspect-ratio-16-9 .aspect-ratio {
	padding-top: 56.25%;
}
