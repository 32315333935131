/**
 * List styles.
 */

ol, ul {
	margin: 0;
}

* + ol,
* + ul {
	margin-top: var(--gap-size);
}
