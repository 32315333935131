/**
 * Styles for the item summaries in list pages.
 */

.moocow-preview {
	margin: 1em 0;
}

.moocow-preview + .moocow-preview {
	margin-top: var(--gap-size);
}

@media (min-width: 27.5rem) {
	.moocow-preview {
		display: flex;
	}
}

.moocow-preview--image {
	flex-shrink: 0;
	margin-bottom: var(--gap-size-small);
}

@media (min-width: 27.5rem) {
	.moocow-preview--image {
		margin-bottom: 0;
		margin-right: var(--gap-size-small);
		width: 12.5rem;
	}
}

.moocow-preview--image a {
	border: none;
	display: block;
}

.moocow-preview--image 	img {
	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.moocow-preview--header h4 {
	font-style: italic;
	font-weight: normal;
	margin: 0;
}

.moocow-preview--header a {
	border-bottom: none;
	color: var(--font-colour);
}

.moocow-preview--header a:hover {
	color: var(--link-colour-hover);
}

.moocow-preview--info {
	color: var(--font-colour-secondary);
	font: 0.85em 'Open Sans', sans-serif;
}

/* Draft modifications */
/* -------------------------------------------------------------------------- */

.moocow-preview-is-draft .moocow-preview--title::after {
	color: var(--font-marker-colour);
	content: ' (draft)';
	font-style: italic;
}
