
/* Marking editable items */
/* -------------------------------------------------------------------------- */

.moocow-editable {
	position: relative;
}

.moocow-editableheroimage {
	cursor: pointer;
}

.moocow-editing-hidden {
	display: none;
}

.moocow-dropfocus {
	box-shadow: 0 0 4px var(--focus-colour-transparent);
}

.moocow-generatedtext {
	color: var(--font-colour-secondary);
}
