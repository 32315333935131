/**
 * Label & text input combined as a single component
 */

.moocow-inputgroup {
	position: relative;
}

.moocow-inputgroup + .moocow-inputgroup {
	margin-top: var(--gap-size-xsmall);
}

.moocow-inputgroup label {
	color: var(--link-colour-hover);
	font-family: 'Open Sans', sans-serif;
	font-size: 0.8rem;
	font-weight: normal;
	left: 0.4rem;
	position: absolute;
}

.moocow-inputgroup input,
.moocow-inputgroup textarea {
	background-color: transparent;
	border-radius: 0;
	color: var(--font-colour);
	outline: none;
	transition: border-color 0.25s;
	width: 100%;
}

.moocow-inputgroup input {
	border: none;
	border-bottom: 1px solid var(--border-colour);
	margin-top: 1.1rem;
	padding: 0 0.4rem;
}

.moocow-inputgroup textarea {
	border: 1px solid var(--border-colour);
	margin-top: 1.5rem;
	padding: 0.2em 0.4rem;
}

.moocow-inputgroup input:focus,
.moocow-inputgroup textarea:focus {
	border-color: var(--focus-colour);
}

.moocow-inputgroup input + label,
.moocow-inputgroup textarea + label {
	opacity: 0;
	top: 1em;
	transition: opacity 0.4s, top 0.4s;
}

.moocow-inputgroup input:not(:placeholder-shown) + label,
.moocow-inputgroup textarea:not(:placeholder-shown) + label{
	opacity: 1;
	top: 0;
}
