
.moocow-form {
	background-color: var(--background-colour-transparent);
	max-width: 25rem;
	padding: 1.5em;
	width: 100%;
}

.moocow-form p {
	text-align: center;
}

.moocow-form--header {
	margin-bottom: var(--gap-size-small);
	text-align: center;
}

.moocow-form--title {
	font-size: 1.2em;
	font-weight: normal;
	margin: 0;
}

.moocow-form--error {
	color: var(--font-marker-colour);
	font-size: 0.9em;
}


/* Where buttons and the like live */
/* -------------------------------------------------------------------------- */

.moocow-form--controlgroup {
	margin-top: var(--gap-size);
}

.moocow-form--controlgroup .moocow-button {
	width: 100%;
}
