/**
 * Paragraph styles.
 */

p {
	margin: 0;
}

* + p {
	margin-top: var(--gap-size);
}
