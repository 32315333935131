/**
 * Content break styles.
 */

hr {
	border-bottom: none;
	border-left: none;
	border-right: none;
	border-top: 2px dotted var(--border-colour);
	margin: var(--gap-size-large) auto;
	width: 50%;
}
