/*
 * Styles used during the transitioning of routes.
 */

@import '../settings/animations.css';

.moocow-route {
	opacity: 1;
}

.moocow-route-before {
	animation: content-fade 400ms ease forwards;
}

.moocow-route-after {
	animation: content-appear 400ms ease forwards;
}
