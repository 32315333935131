
/* Editing controls */
/* -------------------------------------------------------------------------- */

.moocow-editingcontrols {
	position: absolute;
	right: 0;
	top: 0;
	z-index: 1;
}

.moocow-editingcontrols .moocow-menu--trigger {
	fill: var(--font-colour);
}

.moocow-editingcontrols .moocow-menu--trigger:hover {
	fill: var(--link-colour);
}
