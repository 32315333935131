/**
 * Styles for blocks that contain text to distinguish from standard paragraphs
 * in some way.
 */

blockquote, pre {
	background-color: var(--block-background-colour);
	border-left: 0.25em solid var(--block-border-colour);
	margin: 0;
	overflow-x: auto;
	padding: var(--gap-size-small);
}

blockquote {
	font-style: italic;
}

* + blockquote,
* + pre {
	margin-top: var(--gap-size);
}
