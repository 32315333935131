/**
 * Anchor/link styles.
 */

a {
	border-bottom: 1px dotted currentColor;
	color: var(--link-colour);
	text-decoration: none;
	transition: color 0.25s;
}

a:hover {
	color: var(--link-colour-hover);
}
