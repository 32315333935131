/**
 * A special style for better showcasing static templates.
 */

.moocow-prototype {
	background-color: var(--background-colour-transparent);
	min-height: 100vh;
	padding: 1rem;
}

.moocow-prototype--container {
	background-color: var(--background-colour);
	box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.5);
	display: flex;
	flex-flow: wrap;
	flex-grow: 1;
	justify-content: center;
}
