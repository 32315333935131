/**
 * Styles for the site navigation bar along the top.
 */

.moocow-sitenav {
	--nav-background-colour: rgba(144, 144, 144, 0.9);
	--nav-font-colour: #ffffff;

	background-color: var(--nav-background-colour);
	font-size: 0.9em;
	padding-left: env(safe-area-inset-left);
	padding-right: env(safe-area-inset-right);
	position: fixed;
	width: 100%;
	z-index: 10;
}

@media (prefers-color-scheme: dark) {
	.moocow-sitenav {
		--nav-background-colour: rgba(112, 112, 112, 0.9);
		--nav-font-colour: #e0e0e0;
	}
}

.moocow-sitenav--list {
	align-items: baseline;
	display: flex;
	justify-content: center;
	list-style: none;
	margin: 0 auto;
	max-width: 62.5rem;
	padding: 0;
}

.moocow-sitenav--listitem {
	background-color: transparent;
	border: none;
	border-bottom: 0.25em solid transparent;
	color: var(--nav-font-colour);
	cursor: pointer;
	display: inline-block;
	font-family: 'Merriweather', serif;
	padding: 1em 1em 0.75em;
	transition: color 0.25s, border-bottom-color 0.25s;
}

.moocow-sitenav--listitem:hover {
	border-bottom-color: var(--link-colour-hover-light);
	color: var(--link-colour-hover-light);
}

.moocow-sitenav--listitem-is-selected {
	border-bottom: 0.25em solid var(--nav-font-colour);
}

.moocow-sitenav--blurb {
	display: none;
}

.moocow-sitenav--blurb .moocow-sitenav--listitem {
	border-bottom: none;
}

@media (min-width: 37.5rem) {
	.moocow-sitenav {
		font-size: 1em;
	}
}

@media (min-width: 43.75rem) {
	.moocow-sitenav--list {
		justify-content: flex-start;
	}
	.moocow-sitenav--blurb {
		display: block;
		flex-grow: 1;
	}
}
