
.moocow-floatingimage {
	box-shadow: 0.0625em 0.0625em 0.25em #000000;
	margin: 0 auto 1em;
	width: 250px;
}

@media (min-width: 34.375rem) {
	.moocow-floatingimage {
		float: right;
		margin: 0 0 -10em 1em;
		position: relative;
		right: 0;
		top: -10em;
	}
}
