
.moocow-error {
	margin: 0 auto;
	max-width: 43.75rem;
	padding: 1rem;
}

@media (min-width: 50rem) {
	.moocow-error {
		padding: 1.5rem;
	}
}
