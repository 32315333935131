/**
 * Styles for media elements.
 */

img {
	display: block;
	max-height: 100%;
	max-width: 100%;
}

video {
	max-width: 100%;
}
