/**
 * General purpose loading message to display atop everything else.
 */

@import '../settings/animations.css';

.moocow-loading {
	align-items: center;
	animation: content-appear 400ms ease forwards;
	background-color: var(--background-colour-transparent);
	display: flex;
	height: 100vh;
	justify-content: center;
	left: 0;
	opacity: 0;
	position: fixed;
	top: 0;
	width: 100vw;
}

.moocow-loading-delay-for-route {
	animation-delay: 1.4s;
}
