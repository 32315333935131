/**
 * Styles for HTML sectioning tags.
 */

article, section {
	margin: var(--gap-size) 0;
}

article:first-child,
section:first-child {
	margin-top: 0;
}

article:last-child,
section:last-child {
	margin-bottom: 0;
}

header {
	margin-bottom: var(--gap-size);
}

footer {
	margin-top: var(--gap-size);
}
