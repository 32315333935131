/**
 * Common variables used across the website.
 */

:root {
	--background-colour: #ffffff;
	--background-colour-transparent: rgba(255, 255, 255, 0.9);
	--border-colour: #b0b0b0;
	--font-colour: #000000;
	--font-colour-secondary: #808080;
	--font-heading-colour: #222222;
	--font-marker-colour: #c04040;

	--focus-colour: #4080c0;
	--focus-colour-transparent: rgba(64, 128, 192, 0.75);

	--block-background-colour: #ebebeb;
	--block-border-colour: #b0b0b0;

	--link-colour: #404080;
	--link-colour-hover: #8080c0;
	--link-colour-hover-light: #b3b3d9;

	--button-colour-hover: #d8d8ff;

	--gap-size-xsmall: 0.4em;
	--gap-size-small: 0.8em;
	--gap-size: 1.6em;
	--gap-size-large: 2.4em;
	--gap-size-xlarge: 3.2em;
}

@media (prefers-color-scheme: dark) {
	:root {
		--background-colour: #202020;
		--background-colour-transparent: rgba(34, 34, 34, 0.9);
		--font-colour: #e0e0e0;
		--font-colour-secondary: #a0a0a0;
		--font-heading-colour: #dddddd;

		--link-colour: #a0a0e0;
		--link-colour-hover: #c0c0f0;
		--link-colour-hover-light: #c0c0f0;

		--button-colour-hover: #d8d8ff;

		--block-background-colour: #444444;
		--block-border-colour: #909090;
	}
}
