/**
 * Stylesheet for "articles".
 */

.moocow-article + .moocow-article {
	margin-top: var(--gap-size-xlarge);
}

.moocow-article--header {
	margin-bottom: 1.6em;
}

.moocow-article--title {
	margin: 0 0 0.25em;
}

@media (min-width: 37.5rem) {
	.moocow-article--title {
		font-size: 2em;
	}
}

.moocow-article--title a {
	color: var(--font-colour);
}

.moocow-article--title a:hover {
	color: var(--link-colour);
}

.moocow-article--info {
	color: var(--font-colour-secondary);
	font: 0.85em 'Open Sans', sans-serif;
}

.moocow-article--footer {
	border-top: 1px dotted var(--font-colour-secondary);
	color: var(--font-colour-secondary);
	font-size: 0.85em;
	margin-top: var(--gap-size-large);
	padding-top: var(--gap-size);
	text-align: left;
}

/* Draft modifications */
/* -------------------------------------------------------------------------- */

.moocow-article-is-draft .moocow-article--title a::after {
	color: var(--font-marker-colour);
	content: ' (draft)';
	font-style: italic;
}
