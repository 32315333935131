/**
 * Header styles.
 */

h1, h2, h3, h4, h5, h6 {
	color: var(--font-heading-colour);
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
	border-bottom: none;
}

h1, h2 {
	font-size: 1.5em;
}

h1, h2, h3 {
	margin: 0 0 var(--gap-size-small);
}

* + h1,
* + h2,
* + h3 {
	margin-top: var(--gap-size);
}

h2, h3 {
	border-bottom: 1px dotted var(--font-colour);
	padding-bottom: var(--gap-size-xsmall);
}
