/**
 * Styles for the site footer.
 */

.moocow-sitefooter {
	--footer-background-colour: #c0c0c0;
	--footer-font-colour: #606060;

	background-color: var(--footer-background-colour);
	color: var(--footer-font-colour);
	font: 0.8em 'Open Sans', sans-serif;
	line-height: 2.4;
}

@media (prefers-color-scheme: dark) {
	.moocow-sitefooter {
		--footer-background-colour: #303030;
		--footer-font-colour: #a0a0a0;
	}
}

.moocow-sitefooter a {
	border-bottom-color: currentColor;
	color: inherit;
}

.moocow-sitefooter a:hover {
	color: var(--link-colour);
}

.moocow-sitefooter p {
	margin: 0;
}

.moocow-sitefooter--body {
	display: grid;
	grid-template-columns: minmax(1rem, 5%) 1fr minmax(1rem, 5%);
	list-style: none;
	margin: 0 auto;
	max-width: 50rem;
	padding: 1em 0;
}

.moocow-sitefooter--body > * {
	grid-column: 2;
}
